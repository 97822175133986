.toast {
  box-sizing: border-box;
  padding: 1rem;
  background-color: #2d80f2;
  border-radius: 15px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 58px;
  transition: transform 300ms ease-in-out;
  overflow: hidden;
  color: #fff;
  font-family: 'Roboto', sans-serif;
}

.toast-container[data-position$='-right'] .toast {
  transform: translateX(110%);
}

.toast-container[data-position$='-left'] .toast {
  transform: translateX(-110%);
}

.toast-container[data-position='top-center'] .toast {
  transform: translateY(-100vh);
}
.toast-container[data-position='bottom-center'] .toast {
  transform: translateY(100vh);
}

.toast-container .toast.show {
  transform: translate(0, 0);
}
.toast.progress::before {
  content: '';
  position: absolute;
  height: 4px;
  width: calc(100% * var(--progress));
  background: #a00bf6;
  left: 0;
  bottom: 0;
  right: 0;
  margin-right: auto;
}
.toast.can-close::after {
  content: '\00D7';
  position: absolute;
  top: 6px;
  right: 10px;
}

.toast-container {
  position: fixed;
  margin: 10px;
  width: 196px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  z-index: 99999999;
}
.toast-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.toast-content strong {
  font-weight: 400;
  font-size: 1.125rem;
}
.toast-content p {
  font-size: 0.625rem;
  font-weight: 300;
  margin: 0;
}

.toast-container[data-position^='top-'] {
  top: 0;
}

.toast-container[data-position^='bottom-'] {
  bottom: 0;
}

.toast-container[data-position$='-right'] {
  right: 0;
}
.toast-container[data-position$='-left'] {
  left: 0;
}
.toast-container[data-position$='-center'] {
  left: 50%;
  transform: translateX(-50%);
}
